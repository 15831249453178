import React from "react";

import { useEffect, type FC } from "react";
import { useWidgetSettingsContext } from "../settings";
import { useBoolean } from "../hooks/use-boolean";
import { getWidgetById } from "../lib/firestore";
import { Stack } from "@mui/material";
import { LoadingScreen } from "../components/loading-screen";
import { doc, getDoc } from 'firebase/firestore';
import { widgetDB } from '../lib/firebase';

export const withSetWidgetSettings = <P extends object>(
  Component: FC<P>,
  widgetId: string,
  isHome?: boolean,
): FC<P> => {
  return function SetWidgetSettings(props: P) {
    const widgetSettings = useWidgetSettingsContext();

    const applySettings = useBoolean(false);

    useEffect(() => {
      getWidgetById(widgetId).then((widget) => {
        if (widget) {
          widgetSettings.setSettings(
            'assistant',
            (widget?.assistantId || '') as any,
          );
          widgetSettings.setSettings(
            'plan',
            widget.stripe?.isPaid ? 'PRO' : '',
          );
          widgetSettings.setSettings('name', widget.name);
          widgetSettings.setSettings('assistantAvatar', {
            preview: widget.customizations.assistantAvatar,
          } as any);
          widgetSettings.setSettings('launcherIcon', {
            preview: widget.customizations.launcherIcon,
          } as any);

          widgetSettings.setSettings(
            'welcomeMessage',
            widget.customizations.welcomeMessage,
          );



          widgetSettings.setSettings("redirectToWhatsapp", widget?.redirectToWhatsapp);
          widgetSettings.setSettings("whatsappNumber", widget?.whatsappNumber);
          widgetSettings.setSettings('bottomSpacing', widget.position.y);
          widgetSettings.setSettings('sideSpacing', widget.position.x);
          widgetSettings.setSettings(
            'chatPersistence',
            widget.persistChat ? 'tabClosed' : 'never',
          );
          widgetSettings.setSettings('description', widget.description);
          widgetSettings.setSettings(
            'noLauncherBg',
            widget.customizations?.noLauncherBg,
          );

          widgetSettings.setSettings(
            'welcomeMessageColor',
            widget.customizations.welcomeMessageColor || '',
          );

          widgetSettings.setSettings(
            'launcherIconSizeDesktop',
            widget.customizations.launcherIconSizeDesktop,
          );
          widgetSettings.setSettings(
            'launcherIconSizeMobile',
            widget.customizations.launcherIconSizeMobile,
          );

          widgetSettings.setSettings(
            'inputPlaceholder',
            widget.inputPlaceholder,
          );
          widgetSettings.setSettings(
            'laucherBgColor',
            widget.customizations.laucherBgColor,
          );
          widgetSettings.setSettings(
            'mainColor',
            widget.customizations.mainColor,
          );
          widgetSettings.setSettings('mode', widget.customizations.mode);
          widgetSettings.setSettings(
            'position',
            widget.position.isRight ? 'right' : 'left',
          );

          widgetSettings.setSettings(
            'prefillMessageToStartAssistant',
            widget.prefillMessageToStartAssistant,
          );

          widgetSettings.setSettings('startMessage', widget.startMessage);
          widgetSettings.setSettings('poweredBy', false);
          widgetSettings.setSettings('ownerUid', widget.ownerUid);
          widgetSettings.setSettings('widgetId', widgetId);

          widgetSettings.onUpdate('themeMode', widget.customizations.mode);

          const ownerDocRef = doc(widgetDB, `users/${widget.ownerUid}`);
          getDoc(ownerDocRef)
            .then((ownerDoc) => {
              const owner = ownerDoc.data();

              if (owner) {
                const userHasHighPlan = [
                  'price_1Q0vFGERYpQbaFQQpioSZpej',
                  'price_1Q0vFoERYpQbaFQQPl2ApLCj',
                ].includes(owner?.stripe?.priceId || '-');
                if (userHasHighPlan) {
                  widgetSettings.setSettings('plan', 'PRO');
                }
              }
            })
            .catch((error) => {})
            .finally(() => {
              applySettings.onTrue();
            });
        }
      });
    }, [widgetId]);

    if (isHome && (!widgetId || !applySettings.value)) {
      return (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: '100vh',
            width: '100vw',
          }}
        >
          <LoadingScreen />
        </Stack>
      );
    }

    if (!widgetId || !applySettings.value) {
      return null;
    }

    return <Component {...props} />;
  };
};
